import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  direction: 'right'
};

export const directionDrawerSlice = createSlice({
  name: 'dirDrawerSlice',
  initialState,
  reducers: {
    changeDir: (state, action) => {
      if (action.payload == 'en') {
        state.direction = 'right';
      } else {
        state.direction = 'left';
      }
    }
  }
});
