import { configureStore } from '@reduxjs/toolkit';
import { directionDrawerSlice } from './directionDrawer/dirSlice';

const store = configureStore({
  reducer: {
    /**
     * NameSlice: called reducer
     */
    dirDrawerSlice: directionDrawerSlice.reducer
  }
});

export default store;
