import React, { useState, useEffect } from 'react';
import { Select, Spin, Empty } from 'antd';
import debounceInput from '../function/debounceInput';
import { useAxiosGet } from '../configs/axios';

const RemoteSelect = ({
  placeholder,
  disabled,
  className,
  rtl,
  endpoint,
  mode,
  optionText,
  optionValue,
  exclude,
  onValueChange,
  allowClear,
  valueOption,
  labelOption,
  filterFromServer,
  style,
  addExtraData,
  query,
  dependency,
  selectedValue
}) => {
  const [data, setData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [value, setValue] = useState(selectedValue || []);
  const { request, loading } = useAxiosGet(endpoint, {
    limit: 100,
    offset: 0
  });

  useEffect(() => {
    if (endpoint) {
      loadData();
    }
  }, [dependency]);

  const onChange = (selectedValues) => {
    setValue(selectedValues);
    try {
      const keys = selectedValues ? selectedValues.map((v) => v.value) : [];
      const objects = apiData.filter((d) => keys.includes(d[valueOption]));
      onValueChange({ keys, selectedValues, objects });
    } catch (e) {
      console.log(e);
    }
  };

  const onSearch = (searchQuery) => {
    loadData(searchQuery);
  };

  const loadData = async (searchQuery) => {
    try {
      const res = await request({
        limit: 100,
        offset: 0,
        search: searchQuery,
        ...query
      });

      let array = exclude
        ? res.filter((value) => value[valueOption] !== exclude)
        : res;

      const options = array.map((element) => ({
        label:
          typeof optionText === 'function'
            ? optionText(element)
            : element[labelOption],
        value:
          typeof optionValue === 'function'
            ? optionValue(element)
            : element[valueOption]
      }));

      setData(options);
      setApiData(array);
    } catch (error) {
      setData([]);
    }
  };

  const onSearchDebounce = debounceInput((e) => onSearch(e));

  if (addExtraData && data.length > 0 && data[0].value !== 0) {
    try {
      data.unshift(...addExtraData);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Select
      allowClear={allowClear}
      mode={mode || null}
      showSearch
      labelInValue
      disabled={disabled}
      value={value}
      notFoundContent={
        loading ? (
          <Spin size="small" />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      filterOption={
        !filterFromServer
          ? (input, option) =>
              (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
          : false
      }
      onSearch={filterFromServer ? onSearchDebounce : undefined}
      onChange={onChange}
      style={style || {}}
      placeholder={placeholder || ''}
      className={className || undefined}
    >
      {data.map((val) => (
        <Select.Option
          key={val.value}
          value={val.value}
          style={{ fontFamily: rtl ? 'kurdishFont' : undefined }}
        >
          {val.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default RemoteSelect;
