import React from 'react';
import { Button, Form, Input, notification } from 'antd';
import { Navigate } from 'react-router-dom';
import useLocalStorage from '../configs/localStorage';
import { useAxiosPost } from '../configs/axios';
import { localStorageName } from '../configs/constants';
import logo from '../assets/images/logo.png';
import logIn from '../assets/images/login.gif';
import '../assets/styles/pages/login.scss';

const Login = () => {
  const [auth, setAuth] = useLocalStorage(localStorageName, '');
  const { request: loginRequest, loading: loginLoading } =
    useAxiosPost('/auth/login');

  const handleLogin = async (values) => {
    try {
      const { data } = await loginRequest({
        username: values.username,
        password: values.password
      });

      const { user, token, privileges } = data;

      if (user && token && privileges) {
        setAuth({
          token: token,
          user: user,
          privileges: privileges
        });
        location.replace('/');
      }
    } catch (error) {
      notification.error({
        message: "Can't login",
        description: error.response?.data.message,
        placement: 'bottomRight'
      });

      console.error('Error during login:', error);
    }
  };

  if (auth) {
    return <Navigate to="/" />;
  }

  return (
    <main className="main-login">
      <img alt="lezzoo" src={logo} />
      <div className="form-container">
        <div className="form">
          <Form
            layout="vertical"
            style={{ width: '100%', marginTop: '50px' }}
            onFinish={handleLogin}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!'
                }
              ]}
            >
              <Input size="large" placeholder="Username" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!'
                }
              ]}
            >
              <Input size="large" placeholder="Password" type="password" />
            </Form.Item>

            <Button
              type="primary"
              size="large"
              style={{
                width: '100%',
                borderRadius: '40px',
                backgroundColor: '#dd283b'
              }}
              loading={loginLoading}
              htmlType="submit"
            >
              Login
            </Button>
          </Form>
        </div>
        <div>
          <img src={logIn} alt="" />
        </div>
      </div>
    </main>
  );
};

export default Login;
