import React from 'react';
import { Result, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Result
        status="403"
        title="403 Access Denied"
        icon={<ExclamationCircleOutlined />}
        subTitle="Sorry, you do not have permission to access this page."
        extra={
          <Link to="/">
            <Button type="primary">{t('Go Back to Home')}</Button>
          </Link>
        }
      />
    </div>
  );
};

export default Unauthorized;
