import React, { Suspense, useState, createContext, useEffect } from 'react';
import { Routes, Route, NavLink, useLocation } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import {
  Layout,
  Menu,
  theme,
  ConfigProvider,
  Row,
  Button,
  DatePicker,
  Dropdown
} from 'antd';
import { useTranslation } from 'react-i18next';
import routes from './routes';
import Login from './pages/Login';
import './assets/styles/global.scss';
import logo from './assets/images/logo.png';
import useLocalStorage from './configs/localStorage';
import { localStorageName } from './configs/constants';
import RemoteSelect from './components/RemoteSelect';
import { useNavigate } from 'react-router-dom';
import Unauthorized from './components/UnauthorizedComponent';
import { useDispatch } from 'react-redux';
import { directionDrawerSlice } from './store/directionDrawer/dirSlice';

const { Header, Sider, Content } = Layout;

const LANG_STORAGE_KEY = 'userLanguage';

export const merchantContext = createContext(null);

const App = () => {
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();

  const routesArray = routes();

  const dispatch = useDispatch();

  const location = useLocation();

  const [selectedMenuItem, setSelectedMenuItem] = useState(location.pathname);

  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer }
  } = theme.useToken();
  const [auth, setAuth] = useLocalStorage(localStorageName, null);
  const [selectedMerchant, setSelectedMerchant] = useState([]);
  const [selectedDate, setSetlectedDate] = useState(null);

  useEffect(() => {
    const storedLanguage = localStorage.getItem(LANG_STORAGE_KEY);
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  if (location.pathname === '/login' || !auth) {
    return <Login />;
  }

  function handleChangeLang(lang) {
    // Update language in i18n
    i18n.changeLanguage(lang);
    // Store the language preference in local storage
    localStorage.setItem(LANG_STORAGE_KEY, lang);
    // Set the language to lang
    document.documentElement.lang = lang;
    dispatch(directionDrawerSlice.actions.changeDir(lang));
  }

  const items = [
    {
      key: '1',
      label: (
        <Button
          style={{ display: 'block', margin: 'auto', width: '100%' }}
          onClick={() => handleChangeLang('en')}
        >
          <span style={{ fontFamily: 'regular-en' }}>English</span>
        </Button>
      )
    },
    {
      key: '2',
      label: (
        <Button
          style={{
            display: 'block',
            margin: 'auto',
            width: '100%',
            fontFamily: 'regular-ar-kr'
          }}
          onClick={() => handleChangeLang('kr')}
        >
          <span style={{ fontFamily: 'regular-ar-kr' }}>کوردی</span>
        </Button>
      )
    },
    {
      key: '3',
      label: (
        <Button
          style={{
            display: 'block',
            margin: 'auto',
            width: '100%'
          }}
          onClick={() => handleChangeLang('ar')}
        >
          <span style={{ fontFamily: 'regular-ar-kr' }}>عربی</span>
        </Button>
      )
    }
  ];
  
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#dd283b'
        }
      }}
      direction={i18n.language == 'en' ? 'ltr' : 'rtl'}
    >
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            flex: '0 0 auto'
          }}
        >
          <div
            style={{
              height: '64px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '16px'
            }}
          >
            <img src={logo} alt="Logo" style={{ maxWidth: '100%' }} />
          </div>
          <Menu theme="dark" mode="inline" selectedKeys={[selectedMenuItem]}>
            {routesArray.map((item) => {
              if (item.path === '/login' && auth) {
                return null;
              }
              return (
                <Menu.Item
                  key={item.path}
                  title={item.label}
                  onClick={() => setSelectedMenuItem(item.path)}
                >
                  <NavLink to={item.path}>
                    <span>{item.icon}</span>
                    {'  '}
                    <span
                      style={{
                        visibility: collapsed ? 'hidden' : 'visible'
                      }}
                    >
                      {t(item.label)}
                    </span>
                  </NavLink>
                </Menu.Item>
              );
            })}
          </Menu>
        </Sider>
        <Layout
          style={{
            display: 'flex',
            minHeight: '100vh'
          }}
        >
          <Header
            style={{
              padding: 0,
              background: colorBgContainer
            }}
          >
            <Row
              justify="space-between"
              align="middle"
              style={{
                width: '95%',
                height: '100%'
              }}
            >
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: () => setCollapsed(!collapsed)
                }
              )}
              <DatePicker.RangePicker
                onChange={(date) => {
                  setSetlectedDate(date);
                }}
              />
              <RemoteSelect
                placeholder={t('Select Merchant')}
                style={{ minWidth: '270px' }}
                allowClear
                filterFromServer={true}
                endpoint="/generalData/merchants"
                valueOption="merchant_id"
                mode={'multiple'}
                optionText={(obj) =>
                  obj.merchant_name + ` - ${obj.merchant_city}`
                }
                query={{
                  limit: 10
                }}
                defaultValue={selectedMerchant}
                onValueChange={({ keys }) => {
                  setSelectedMerchant(keys);
                }}
              />
              <Dropdown
                menu={{ items }}
                placement="bottom"
                arrow
                trigger={['click']}
              >
                <Button
                  style={{ textTransform: 'capitalize', minWidth: '110px' }}
                >
                  {i18n.language == 'en'
                    ? 'English'
                    : i18n.language == 'kr'
                    ? 'کوردی'
                    : 'عربی'}
                </Button>
              </Dropdown>
              <Button
                onClick={() => {
                  try {
                    setAuth(null);
                    navigate('/login');
                  } catch (error) {
                    console.log('Error:', error);
                  }
                }}
              >
                {t('Logout')}
              </Button>
            </Row>
          </Header>
          <Content
            style={{
              marginTop: '24px',
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
              width: '100%',
              overflowY: 'auto',
              flex: 1
            }}
          >
            <div
              style={{
                padding: '24px'
              }}
            >
              <merchantContext.Provider
                value={{ selectedMerchant, selectedDate, auth }}
              >
                <Routes>
                  {routesArray.map((route) => {
                    if (route.permissions) {
                      const hasPermissions = route.permissions.every(
                        (permission) =>
                          auth &&
                          auth.privileges.permissions.includes(permission)
                      );

                      if (hasPermissions) {
                        return (
                          <Route
                            path={route.path}
                            key={route.key}
                            element={
                              <Suspense fallback={<>...</>}>
                                <route.component />
                              </Suspense>
                            }
                            exact
                          />
                        );
                      } else {
                        return (
                          <Route
                            path={route.path}
                            key={route.key}
                            element={<Unauthorized />}
                            exact
                          />
                        );
                      }
                    } else {
                      return (
                        <Route
                          path={route.path}
                          key={route.key}
                          element={
                            <Suspense fallback={<>...</>}>
                              <route.component />
                            </Suspense>
                          }
                        />
                      );
                    }
                  })}
                </Routes>
              </merchantContext.Provider>
            </div>
            <footer
              style={{
                padding: '20px 0',
                fontSize: '14px',
                transition: 'all 0.3s',
                borderTop: '1px solid #cddfff',
                textAlign: 'center',
                color: '#012970',
                paddingTop: '5px'
              }}
            >
              <div style={{ textAlign: 'center', color: '#012970' }}>
                &copy; Copyright{' '}
                <a href="https://lezzoo.com/">
                  <strong>
                    <span>LEZZOO INC.</span>
                  </strong>
                </a>{' '}
                All Rights Reserved
              </div>
            </footer>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default App;
