import { lazy } from 'react';
import {
  HomeOutlined,
  OrderedListOutlined,
  AreaChartOutlined,
  GoldOutlined,
  CompassOutlined,
  ReconciliationOutlined,
  UserOutlined,
  StarOutlined
} from '@ant-design/icons';
import './i18n';
import { useTranslation } from 'react-i18next';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const Finance = lazy(() => import('./pages/Finance'));
const OrderTracking = lazy(() => import('./pages/OrderTracking'));
const SalesAnalytics = lazy(() => import('./pages/SalesAnalytics'));
const TopProducts = lazy(() => import('./pages/TopProducts'));
const Geo = lazy(() => import('./pages/Geo'));
const rating = lazy(() => import('./pages/ratings'));
const Users = lazy(() => import('./pages/users.jsx'));

const routes = () => {
  const { t } = useTranslation();

  return [
    {
      key: Math.random(),
      path: '/login',
      component: Login,
      exact: true
    },
    {
      key: Math.random(),
      path: '/',
      component: Home,
      icon: <HomeOutlined />,
      label: t('Summary Overview'),
      permissions: ['Summary Overview Page - MD']
    },
    {
      key: Math.random(),
      path: '/Finance',
      component: Finance,
      icon: <ReconciliationOutlined />,
      label: t('Financial Reports'),
      permissions: ['Financial Reports Page - MD']
    },
    {
      key: Math.random(),
      path: '/Orders',
      component: OrderTracking,
      icon: <OrderedListOutlined />,
      label: t('Order Tracking'),
      permissions: ['Order Tracking Page - MD']
    },
    {
      key: Math.random(),
      path: '/Sales-Analytics',
      component: SalesAnalytics,
      icon: <AreaChartOutlined />,
      label: t('Sales Analytics'),
      permissions: ['Sales Analytics Page - MD']
    },
    {
      key: Math.random(),
      path: '/Geo',
      component: Geo,
      icon: <CompassOutlined />,
      label: t('Geo Analytics'),
      permissions: ['Geo Analytics Page - MD']
    },
    {
      key: Math.random(),
      path: '/Top-Selling-Products',
      component: TopProducts,
      icon: <GoldOutlined />,
      label: t('Top Products'),
      permissions: ['Top Products Page - MD']
    },
    {
      key: Math.random(),
      path: '/rating',
      component: rating,
      icon: <StarOutlined />,
      label: t('Rating Dashboard'),
      permissions: ['Rating Dashboard - MD']
    },
    {
      key: Math.random(),
      path: '/Users',
      component: Users,
      icon: <UserOutlined />,
      label: t('Users'),
      permissions: ['Users Page - MD']
    }
  ];
};

export default routes;
